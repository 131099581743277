<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.send_content')"
          />
          <q-space />
          <q-btn
            flat
            round
            dense
            color="red"
            icon="close"
            v-close-popup
            @click="clearData()"
          />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <q-card-section class="q-pt-none q-mt-md">
          <div
            style="border-radius: 5px"
            class="bg-grey-4 q-pa-md text-justify q-mb-lg"
          >
            <div
              class="q-ma-sm q-mb-md bg-white q-pa-md default-rounded"
              v-if="step.content.length"
            >
              <div
                v-html="$t('missions.content_feedback')"
                class="text-weight-bolder text-uppercase text-orange-8"
              />
              <q-separator />
              <div class="q-mt-sm">
                <q-icon name="flag" color="orange-8" style="font-size: 16pt" />
                {{ step.content[0].content_feedbacks.observation }}
              </div>
            </div>

            <div class="row">
              <div
                class="text-uppercase text-weight-bolder q-pl-md q-mb-sm"
                v-html="$t('missions.step', { value: step.sequence })"
              />
              <q-space />
              <q-badge
                rounded
                color="primary"
                class="text-uppercase q-pa-sm"
                :label="
                  getFinishDate(
                    step.when_date,
                    step.when_integer,
                    start_current_step
                  )
                "
                v-if="step.when_date || step.when_integer"
              />
            </div>
            <div class="q-ma-sm bg-white q-pa-md default-rounded full-height">
              <span v-html="step.todo" />
            </div>

            <div class="row q-mb-md" style="margin-top: -20px">
              <div
                class="col-12 col-md-4 q-mt-md"
                v-for="(t, i) in step.targets"
                :key="i"
              >
                <div
                  class="q-ma-sm bg-white q-pa-md default-rounded full-height"
                >
                  <span class="text-weight-bold"
                    ><q-icon :name="getTargetIcon(t.target)" />
                    {{ t.target }}
                  </span>
                  <div class="q-mt-sm">
                    <span v-html="t.pivot.description" />
                    <br />
                    <q-badge
                      color="grey-7"
                      class="q-pa-sm q-mt-md"
                      v-if="'hashtag' in t.pivot && t.pivot.hashtag"
                    >
                      #{{ t.pivot.hashtag }}
                    </q-badge>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <q-form class="q-mt-md" greedy @submit="submit()">
            <q-card flat>
              <q-tabs
                v-model="tab"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="justify"
                narrow-indicator
              >
                <q-tab
                  :key="i"
                  :name="t.name"
                  :label="t.label"
                  v-for="(t, i) in tabs"
                />
              </q-tabs>

              <q-separator />

              <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="link">
                  <div class="q-mt-md">
                    <template v-for="(link, i) in content_posted" :key="i">
                      <div
                        class="bg-blue-1 q-pa-md q-mb-md default-rounded"
                        v-if="link.automatic"
                      >
                        <span v-html="link.exception" />
                      </div>
                      <q-input
                        filled
                        class="q-mb-md"
                        :label="link.label"
                        v-model="link.url_posted"
                        :type="
                          link.label.toLowerCase() == 'texto' ? 'text' : 'url'
                        "
                        :rules="[
                          (val) =>
                            val.length > 0 ||
                            $t(`missions.required`, {
                              value: link.label.toLowerCase(),
                            }),
                        ]"
                        ><template v-slot:before
                          ><q-icon :name="link.icon"
                        /></template>
                      </q-input>
                    </template>
                  </div>
                </q-tab-panel>

                <q-tab-panel name="upload">
                  <div class="text-center q-pa-lg">
                    <q-img
                      src="/missions-icons/upload.png"
                      style="width: 100px"
                    />
                  </div>
                  <div class="text-center">
                    <div
                      class="text-weight-bolder"
                      v-html="$t('missions.upload_title')"
                    />
                    <div
                      class="q-mt-sm q-mb-lg"
                      v-html="$t('missions.upload_text')"
                    />
                  </div>
                  <q-file
                    filled
                    clearable
                    v-model="file"
                    @rejected="rejectedFile()"
                    :label="$t('missions.select_file')"
                    :accept="upload_data.type.extension"
                    :max-file-size="upload_data.type.file_size_bytes"
                  >
                    <template v-slot:prepend>
                      <q-icon name="cloud_upload" />
                    </template>
                  </q-file>
                </q-tab-panel>
              </q-tab-panels>
            </q-card>

            <q-card-section v-if="in_request">
              <q-linear-progress
                indeterminate
                rounded
                size="25px"
                color="secondary"
                class="q-mt-sm q-mb-md"
              />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                flat
                rounded
                icon="arrow_back"
                color="black"
                style="width: 150px"
                @click="getBack(tab)"
                :label="$t('missions.back')"
                v-if="tab != tab_labels[0]"
              />
              <q-btn
                flat
                rounded
                icon-right="arrow_forward"
                color="black"
                style="width: 150px"
                @click="getNext(tab)"
                :label="$t('missions.next')"
                v-if="tab != tab_labels[tab_labels.length - 1]"
              />
              <q-btn
                rounded
                color="black"
                type="submit"
                style="width: 150px"
                :loading="in_request"
                :label="$t('missions.send')"
                v-if="tab === tab_labels[tab_labels.length - 1]"
              />
            </q-card-actions>
          </q-form>
        </q-card-section>
      </div>
    </q-card>
    {{ tab_labels }}
  </q-dialog>
</template>

<script>
import {
  getTargetIcon,
  getOptionLabels,
  getFinishDate,
} from "../../helpers/script_helpers";
import { fetchUploadFileToS3 } from "@/shared/helpers/s3Helpers";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { useActions, useGetters } = createNamespacedHelpers("missions");

export default {
  name: "UploadComponent",

  props: {
    step: {
      type: Object,
      required: true,
    },
    start_current_step: {
      type: Number,
      required: true,
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const { fetchCreateContent, fetchTemporaryUploadLinks } = useActions([
      "fetchCreateContent",
      "fetchTemporaryUploadLinks",
    ]);

    const { getTemporaryUploadLinks } = useGetters(["getTemporaryUploadLinks"]);

    const { t } = useI18n(),
      tab = ref("link"),
      tab_labels = ref([]),
      in_request = ref(false);

    const content_posted = ref([]),
      upload_type = ref(""),
      upload_data = ref(null),
      option_labels = ref(1),
      file = ref(null),
      payload = ref({
        mission_todo_id: props.step.id,
        mission_id: props.step.mission_id,
      });

    const tabs = computed(() => {
      const allTabs = {
        1: ["link", "upload"],
        2: ["link"],
        3: ["upload"],
      };

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      tab_labels.value = allTabs[option_labels.value];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      tab.value = allTabs[option_labels.value][0];

      return tab_labels.value.map((tab) => ({
        name: tab,
        label: t(`missions.tabs.${tab}`),
      }));
    });

    const isFormValid = () => {
      if (
        tab_labels.value.includes("upload") &&
        tab_labels.value.includes("link")
      ) {
        return (
          !!file.value &&
          content_posted.value.every((c) => c.url_posted.length > 0)
        );
      } else if (tab_labels.value.includes("upload")) {
        return !!file.value;
      } else if (tab_labels.value.includes("link")) {
        return content_posted.value.every((c) => c.url_posted.length > 0);
      }
    };

    const handleUploadFile = () => {
      const fileAttributes = getFileAttributes(file.value);

      return fetchTemporaryUploadLinks(fileAttributes).then(() =>
        fetchUploadFileToS3(
          getTemporaryUploadLinks.value.url_pre_signed,
          file.value,
          fileAttributes
        )
      );
    };

    const handlePayload = () => {
      var payloadCreateContent = [];

      if (
        tab_labels.value.includes("upload") &&
        tab_labels.value.includes("link")
      ) {
        payloadCreateContent = [
          {
            target_id: upload_type.value.id,
            url_posted: getTemporaryUploadLinks.value.url,
          },
          ...content_posted.value,
        ];
      } else if (tab_labels.value.includes("upload")) {
        payloadCreateContent = [
          {
            target_id: upload_type.value.id,
            url_posted: getTemporaryUploadLinks.value.url,
          },
        ];
      } else if (tab_labels.value.includes("link")) {
        payloadCreateContent = content_posted.value;
      }

      return payloadCreateContent;
    };

    const submit = async () => {
      if (!isFormValid()) notifyError(t("missions.validations.error"));
      else {
        in_request.value = true;

        // Subida de arquivos na AWS
        if (tab_labels.value.includes("upload")) {
          console.log("uploading file");
          await handleUploadFile()
            .then(() => createContentAction())
            .catch(() => {
              notifyError(
                "Falha ao realizar upload do conteúdo. <br/>Entre em contado com o suporte <b>suporte@flip.net.br</b>"
              );
              in_request.value = false;
            });
        } else {
          createContentAction();
        }
      }
    };

    const createContentAction = () => {
      let payloadCreateContent = handlePayload();

      fetchCreateContent({
        mission_todo_id: props.step.id,
        mission_id: props.step.mission_id,
        content_posted: JSON.stringify(payloadCreateContent),
      })
        .then(() => {
          notifySuccess(t("missions.validations.success"));
          emit("close");
          clearData();
        })
        .finally(() => (in_request.value = false));
    };

    const getFileAttributes = (file) => {
      const file_size = file.size,
        extension = file.name.split(".").pop(),
        file_type = upload_data.value.type.mime_type;

      return {
        file_type,
        file_size,
        extension,
        module: "mission_content",
      };
    };

    const clearData = () => {
      payload.value = {
        title: "",
        hashtags: [],
        description: "",
        mission_todo_id: props.step.id,
        mission_id: props.step.mission_id,
      };
      content_posted.value = [];
      file.value = null;
      upload_type.value = "";
      upload_data.value = null;
    };

    onMounted(() => {
      upload_type.value = props.step.targets.find(
        (i) => i.target == t("missions.targets.flip")
      );

      upload_data.value = props.step.mission_include.find(
        (i) => i.target.slug == t("missions.targets.flip").toLocaleLowerCase()
      );

      option_labels.value = getOptionLabels(
        upload_type.value,
        props.step.targets
      );

      content_posted.value = props.step.targets
        .filter((i) => i.target !== t("missions.targets.flip"))
        .map((i) => ({
          url_posted: "",
          target_id: i.id,
          label: i.target,
          automatic: i.automatic_tracking,
          exception: i.tracking_exception,
          icon:
            i.target == t("missions.targets.social")
              ? `fab fa-google-${i.target.toLowerCase()}`
              : i.target == t("missions.targets.social")
              ? "fas fa-globe"
              : `fab fa-${i.target.toLowerCase()}`,
        }));
    });

    return {
      tab,
      tabs,
      file,
      payload,
      tab_labels,
      in_request,
      upload_data,
      upload_type,
      content_posted,
      submit,
      clearData,
      getTargetIcon,
      getFinishDate,
      handleUploadFile,
      getTemporaryUploadLinks,
      getBack: (value) => {
        const index = tab_labels.value.indexOf(value);
        if (index > 0) tab.value = tab_labels.value[index - 1];
      },
      getNext: (value) => {
        const index = tab_labels.value.indexOf(value);
        if (index < tab_labels.value.length - 1)
          tab.value = tab_labels.value[index + 1];
      },
      rejectedFile: () => notifyError(t("missions.file_rejected")),
    };
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 1200px;
  max-width: 80vw;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
