<template>
  <div class="content-missions">
    <div class="q-pa-sm bg-white default-rounded">
      <div
        class="row"
        :class="{
          'justify-center': $q.platform.is.mobile,
          'no-wrap justify-between': $q.platform.is.desktop,
        }"
      >
        <q-tabs dense no-caps align="left" v-model="tab" narrow-indicator>
          <q-tab
            :name="tab"
            :ripple="false"
            :label="$t('missions.' + tab)"
            v-for="(tab, i) in ['notEnrolled', 'inProgress', 'finished']"
            :key="i"
          />
        </q-tabs>
      </div>
    </div>
    <badges />
    <q-tab-panels
      class="bg-transparent"
      v-model="tab"
      animated
      v-if="!in_request"
    >
      <q-tab-panel class="no-scroll no-padding" name="notEnrolled">
        <missions-not-enrolled-component
          :tab="tab"
          :mission_not_enrolled="getNotSubsMissions"
        />
      </q-tab-panel>
      <q-tab-panel class="no-scroll no-padding" name="inProgress">
        <missions-enrolled-component
          :tab="tab"
          :mission_enrolled="getMissions"
        />
      </q-tab-panel>
      <q-tab-panel class="no-scroll no-padding" name="finished">
        <missions-finished-component
          :tab="tab"
          :missions_finished="getFinishedMissions"
        />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import MissionsNotEnrolledComponent from "./components/MissionsNotEnrolledComponent.vue";
import MissionsEnrolledComponent from "./components/MissionsEnrolledComponent.vue";
import MissionsFinishedComponent from "./components/MissionsFinishedComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted, ref } from "vue";
// import { useRoute } from "vue-router";

const { useActions, useGetters } = createNamespacedHelpers("missions");

export default {
  components: {
    MissionsNotEnrolledComponent,
    MissionsEnrolledComponent,
    MissionsFinishedComponent,
  },

  setup() {
    //const route = useRoute();
    const in_request = ref(false);

    const { fetchMissionsServices } = useActions(["fetchMissionsServices"]),
      { getMissions, getNotSubsMissions, getFinishedMissions } = useGetters([
        "getMissions",
        "getNotSubsMissions",
        "getFinishedMissions",
      ]),
      tab = ref("notEnrolled");

    onMounted(() => {
      in_request.value = true;
      fetchMissionsServices().finally(() => (in_request.value = false));
      const isShowFeedback = localStorage.getItem("showFeedBack");

      if (localStorage.getItem("isSubscribed")) {
        tab.value = "inProgress";
        localStorage.removeItem("isSubscribed");
      }

      if (isShowFeedback) tab.value = "inProgress";
    });

    return {
      tab,
      in_request,
      getMissions,
      getNotSubsMissions,
      getFinishedMissions,
    };
  },
};
</script>

<style scoped lang="scss">
.mobile {
  .content-missions {
    margin: 0 -15px;
  }
}
</style>
